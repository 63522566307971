<template>
  <router-link :to="{name: item.path, query: {type: item.query}}">
    <div
      style="height: 250px"
      class="border py-6 flex justify-center align-center rounded-lg border-solid border-gray-400"
    >
      <div class="text-center">
        
        <div v-if="item.svg" v-html="item.icon">

        </div>
      
        <md-icon v-else class="md-size-3x text-victoria">{{ item.icon }}</md-icon>
        <p class="line-height-33 mt-6 text-primary text-3xl text-uppercase font-bold">
          {{ item.name }}
        </p>
        <p v-if="item.file" class="font-base text-gray-500 text-uppercase mt-3">
          ( Total {{ item.file }} File )
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
