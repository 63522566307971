<template>
  <div class="md-layout md-gutter w75p flex justify-center">
    <div 
      class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      v-for="item in items" :key="item.id"
    >
      <BoxNavigationCard :item="item" />
    </div> 
  </div>
</template>

<script>
import data from '@/data/question-creation/data';
import BoxNavigationCard from "@/components/molecule/BoxNavigationCard";

export default {
  props: {
    layout: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    items() {
      return this.layout === 'master' ? data.masterNavigation : data.mainNavigation;
    }
  },
  components: {
    BoxNavigationCard,
  },
};
</script>
